import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Rating, CircularProgress } from '@mui/material';
import { useState} from 'react';
import { collection, doc, deleteDoc, updateDoc, increment } from 'firebase/firestore';
import { db } from "../firebase/config";

const ImageDialog = ({currentUser, updateUser, dialogOpen, handleCloseImageDialog, selectedUpdate, setAlertMessage, setAlertSeverity, setAlertOpen, handleSetRating, handleEditRequest, handleDeleteUpdateExternal}) => {

  const handleDeleteUpdate = async (updateId) => {
    // deleteUpdate(updateId);
    try {
      // Get the reference to the update document
      const updateRef = doc(collection(db, 'updates'), updateId);

      handleDeleteUpdateExternal(updateId)
    
      // Delete the update document
      await deleteDoc(updateRef);

      const userRef = doc(db, "users", updateUser.uid);
      await updateDoc(userRef, {
        updatesCount: increment(-1),
      });

      const teamRef = doc(db, "teams", `${updateUser.groupNo}`);
      await updateDoc(teamRef, {
        updatesCount: increment(-1),
      });
    
      // Update successfully deleted
      // console.log('Update deleted');
      setAlertMessage(`Update deleted!`);
      setAlertSeverity("success");
      setAlertOpen(true);
      handleCloseImageDialog();
    } catch (error) {
      // Handle any errors
      setAlertMessage(`Error deleting update: ${error}`);
      setAlertSeverity("error");
      setAlertOpen(true);
      console.error('Error deleting update:', error);
    }
  };

  const handleRequestEdit = async (updateId) => {
    try {
      // Get the reference to the update document
      const updateRef = doc(collection(db, 'updates'), updateId);
    
      // Update the "update" document
      await updateDoc(updateRef, { isEditRequested: true, isVerified: false });

      handleEditRequest(true);
    
      // Update successfully requested
      console.log('Update requested for edit');
      setAlertMessage(`Update requested for edit!`);
      setAlertSeverity("success");
      setAlertOpen(true);
      handleCloseImageDialog();
    } catch (error) {
      // Handle any errors
      setAlertMessage(`Error requesting edit for update:: ${error}`);
      setAlertSeverity("error");
      setAlertOpen(true);
      console.error('Error requesting edit for update:', error);
    }
  };


  const [rated, setRated] = useState(false);
  const [ratingLoading, setRatingLoading] = useState(false);
  const [rating, setRating] = useState(selectedUpdate!=null ? selectedUpdate.rating ?? 0 : 0);
  const handleRatingChange = async (event, newValue, updateId) => {
    console.log(newValue);
    console.log(rating);

    setRatingLoading(true);
    const newRating = newValue !== null ? newValue : rating || 1;
    if(newRating!==rating){
    try {
      // Update the rating value in the Firestore document
      const updateRef = doc(db, 'updates', updateId);
      await updateDoc(updateRef, {
        rating: newRating,
        isEditRequested: false,
        isVerified: true,
        ratedBy: currentUser.uid,
      });


      if(!rated && !rating){
        const key = `${newRating}rating`; 
        let ratingUpdate = {};
        ratingUpdate[key] = increment(1);
        ratingUpdate["verifiedUpdates"] = increment(1);
        console.log("new rating");
        const ref = doc(db, "users", updateUser.uid);
        await updateDoc(ref,ratingUpdate);

        const teamRef = doc(db, "teams", `${updateUser.groupNo}`);
        await updateDoc(teamRef,ratingUpdate);
      } else {
        const key = `${newRating}rating`; 
        const keyDec = `${rated ? rating : rating}rating`; 
        let ratingUpdate = {};
        ratingUpdate[key] = increment(1);
        ratingUpdate[keyDec] = increment(-1);
        console.log("update rating");
        const ref = doc(db, "users", updateUser.uid);
        await updateDoc(ref,ratingUpdate);

        const teamRef = doc(db, "teams", `${updateUser.groupNo}`);
        await updateDoc(teamRef,ratingUpdate);
      }


      setAlertMessage(`${newRating}.0 Rating added!`);
      setAlertSeverity("success");
      setAlertOpen(true);
      handleCloseImageDialog();
      // Update the local state with the new rating value
      setRating(newRating);
      setRated(true);
      handleSetRating(newRating);
      handleEditRequest(false);
    } catch (error) {
      console.error('Error updating rating:', error);
      // Handle the error as needed
      setAlertMessage(`Error updating rating: ${error}`);
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  }
  setRatingLoading(false);
  };

  if (!selectedUpdate) return null;

  return (
    <Dialog open={dialogOpen} onClose={handleCloseImageDialog}>
      <DialogTitle>{` ${selectedUpdate.formatedDate}`}</DialogTitle>
      <DialogContent>
        <img src={selectedUpdate.fileUrl} alt="Update" style={{ width: '100%' }} />
      </DialogContent>
      <DialogContent>
        <Typography>{selectedUpdate.description}</Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        {(currentUser.isAdmin && !selectedUpdate.isVerified && !rating && !rated) && (
          <Button color="primary" onClick={() => handleDeleteUpdate(selectedUpdate.id)}>
            Delete
          </Button>
        )}
        {currentUser.isAdmin && (
          <Button color="primary" onClick={() => handleRequestEdit(selectedUpdate.id)}>
            Request edit
          </Button>
        )}
        {currentUser.isAdmin && !ratingLoading && (
          <Rating
            name={`update-rating-${selectedUpdate.id}`}
            value={rated ? rating : selectedUpdate.rating ?? 0}
            onChange={(event, value) => handleRatingChange(event, value, selectedUpdate.id)}
            precision={1}
          />
        )}
        {ratingLoading && <CircularProgress size={24} />} {/* Show a loading spinner */}
        <Button onClick={handleCloseImageDialog} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageDialog;
