import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Alert,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useCollection } from "../hooks/useCollection";
import { CustomButton2 } from "./Button";

export default function EnititySelector({collection, title, subTitle, selection }) {
  const [enitity, setEntity] = useState(null);

  const selectEnitity = (value) => {
    setEntity(value);
    console.log(value);
  };

  const next = () => {
    selection(enitity);
  };

  return (
    <>
      <Box>
        <Selector
          collection = {collection ?? "institutions"}
          title= {title ?? "Institution"}
          subTitle= {subTitle ?? "Select your institution from the below list."}
          selection={selectEnitity}
        />

        <Box
          display="flex"
          sx={{
            paddingTop: "2rem",
            
          }}
        >
          <Box
          sx={{
            margin: "0 auto",
            marginBottom: "20px",
          }}
          >
          <CustomButton2
            onClick={next}
          >
            Next
          </CustomButton2>
          </Box>
        </Box>
      </Box>
    </>
  );
}

function Selector({ collection, title, subTitle, selection, id }) {
  const [item, setItem] = useState(null);
  let query = id ? ["institutionId", "==", id] : collection == "internships" ?  ["isActive", "==", true] : null;
  // let query2 = id ? ["isApproved", "==", true] : null;//todo uncomment and add to bellow line
  const { error, documents: items } = useCollection(collection, query);

  // useEffect(() => {
  //   if (items && items.length > 0) {
  //     selection(items[0]);
  //   }
  // }, [items, selection]);

  const selectItem = (e) => {
    setItem(e.target.value);
    selection(e.target.value);
    // console.log(e.target.value);
  };

  return (
    <>
      <Typography
        color="text.primary"
        variant="h5"
        component="h1"
        textAlign={["center"]}
      >
        {title}
      </Typography>
      <Typography
        color="text.primary"
        variant="p"
        component="p"
        textAlign={["center"]}
        sx={{
          maxWidth: "500px",
          paddingBottom: "2rem",
        }}
      >
        {subTitle}
      </Typography>
      {!items && !error && (
        <Typography color="text.primary" textAlign={["center"]}>
          Loading...
        </Typography>
      )}
      {error && <Alert severity="error">{error}</Alert>}
      {items && (
        <SelectItem
          id={collection}
          item={item ?? items[0]}
          items={items}
          selectItem={selectItem}
        />
      )}
    </>
  );
}

function SelectItem({ id, items, item, selectItem }) {
  return (
    <>
      {id === "institutions" && (
        <Box
          component="img"
          alt={item.name}
          src={id === "internships" ? item.imageLink : item.imageLink}
          maxHeight="90px"
          sx={{
            display: "block",
            margin: "0 auto",
            borderRadius: "100px",
            // paddingBottom: "1rem",
          }}
        />
      )}
      <Box height="20px" />
      <TextField
        id={id}
        select
        label={id === "internships" ? "Program" : "Institution"}
        value={item}
        onChange={selectItem}
        // helperText="Please select your currency"
        sx={{
          minWidth: "80px",
          maxWidth: "90vw",
          display: "flex",
          margin: "0 auto",
          paddingBottom: "0.5rem",
          "&:before": {
            borderColor: "white",
          },
          "&:after": {
            borderColor: "white",
          },
        }}
      >
        {items.map((option) => (
          <MenuItem
            key={option.name}
            value={option}
            sx={{
              backgroundColor: "white",
            }}
          >
            <Typography variant="p" color="text.primary">
              {option.name}
            </Typography>
          </MenuItem>
        ))}
      </TextField>
      <Typography
        variant="p"
        component="p"
        color="text.primary"
        textAlign={["center"]}
        sx={{
          paddingTop:"10px",
          maxWidth: "500px",
        }}
      >
        {id === "internships"
          ? `Period of program: ${item.period}`
          : item.address}
      </Typography>
    </>
  );
}
