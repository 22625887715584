import { useState, useEffect } from "react";
import { auth, db, timestamp } from "../firebase/config";
import { useAuthContext } from "./useAuthContext";
import {
  signInWithPopup,
  GoogleAuthProvider,
  updateProfile,
} from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc, increment } from "firebase/firestore";

const provider = new GoogleAuthProvider();

export const useSignup = () => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();

  const signup = async ({ name, isAdmin, phone, department, year, regNo, groupNo, batchNo, qualification, institution, internship}) => {
    setError(null);
    setIsPending(true);

    console.log(name, isAdmin, phone, department, year, regNo, groupNo, batchNo, institution, internship);

    try {
      // signup
      const res = await signInWithPopup(auth, provider);

      if (!res || !res.user) {
        if (!isCancelled) {
          setIsPending(false);
          setError("Login failed");
        }
        throw new Error("Could not complete login");
      } else { console.log(res.user);}

      // user firestore reference
      const ref = doc(db, "users", res.user.uid);

      // console.log("checking if user exists");

      // check is already registered
      const docSnap = await getDoc(ref);
      if (docSnap.exists()) {
        // console.log("User alrdy registered");
        const user = docSnap.data();
        if (user.institutionId) {
          // if (!isCancelled) {
          setIsPending(false);
          setError("Already registered, Loging in...");
          // }
          await new Promise((resolve) => setTimeout(resolve, 3000));
          await dispatch({ type: "LOGIN", payload: res.user });
          throw new Error("Already registered");
        } else if(isAdmin) {
          setIsPending(false);
          setError("Already registered, Loging in...");
          await new Promise((resolve) => setTimeout(resolve, 3000));
          await dispatch({ type: "LOGIN", payload: res.user });
          throw new Error("Already registered");
        }
      } else {
        console.log("New user registeration");
      }

      // upload user thumbnail
      // const uploadPath = `thumbnails/${res.user.uid}/${thumbnail.name}`;
      // const img = await projectStorage.ref(uploadPath).put(thumbnail);
      // const imgUrl = await img.ref.getDownloadURL();

      if(isAdmin){
        await setDoc(
          ref,
          {
            uid: res.user.uid,
            name: res.user.displayName,
            email: res.user.email,
            photoURL: res.user.photoURL,
            isAdmin: true,
            joinedOn: timestamp.fromDate(new Date()),
          },
          { merge: true }
        );
      } 
      else if(internship.isProfessional) {

        // add display AND PHOTO_URL name to user
        // await res.user.updateProfile({ displayName: name, photoURL: imgUrl });
        await updateProfile(res.user, { displayName: name });
  
        
        // create a user document
        await setDoc(
          ref,
          {
            name,
            phone,
            uid: res.user.uid,
            email: res.user.email,
            photoURL: res.user.photoURL,
            internshipId: internship.id,
            internshipName: internship.name,
            groupNo: parseInt(groupNo, 10),
            regNo: parseInt(regNo, 10),
            batchNo: parseInt(batchNo, 10),
            qualification: qualification,
            isProfessional: true,
            isVerified: false,
            isMember: true,
            joinedOn: timestamp.now(),
          },
          { merge: true }
        );
  
        const adminRef = doc(db, "admin", "admin");
        await updateDoc(adminRef, {
          memberCount: increment(1),
        });
  
        // increment reg count for the internship
        const eventRef = doc(db, "internships", internship.id);
        await updateDoc(eventRef, {
          regCount: increment(1),
        });
  
        const teamRef = doc(db, "teams", `${groupNo}`);
        await updateDoc(teamRef, {
          memberCount: increment(1),
        });
      }
      else  {

      // add display AND PHOTO_URL name to user
      // await res.user.updateProfile({ displayName: name, photoURL: imgUrl });
      await updateProfile(res.user, { displayName: name });

      
      // create a user document
      await setDoc(
        ref,
        {
          name,
          phone,
          uid: res.user.uid,
          email: res.user.email,
          photoURL: res.user.photoURL,
          institutionId: institution.id,
          institutionName: institution.name,
          internshipId: internship.id,
          internshipName: internship.name,
          groupNo: parseInt(groupNo, 10),
          regNo: parseInt(regNo, 10),
          batchNo: parseInt(batchNo, 10),
          department: department,
          year: year,
          isVerified: false,
          isMember: true,
          joinedOn: timestamp.now(),
        },
        { merge: true }
      );

      // increment member count for the institution
      const institutionRef = doc(db, "institutions", institution.id);
      await updateDoc(institutionRef, {
        memberCount: increment(1),
      });

      const adminRef = doc(db, "admin", "admin");
      await updateDoc(adminRef, {
        memberCount: increment(1),
      });

      // increment reg count for the internship
      const eventRef = doc(db, "internships", internship.id);
      await updateDoc(eventRef, {
        regCount: increment(1),
      });

      const teamRef = doc(db, "teams", `${groupNo}`);
      await updateDoc(teamRef, {
        memberCount: increment(1),
      });
    }

      // dispatch login action
      dispatch({ type: "LOGIN", payload: res.user });

      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      if (!isCancelled) {
        setError(err.message);
        setIsPending(false);
        console.log(err.message);
      }
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { signup, error, isPending };
};
