import React from 'react';
import { Document, Page, Text, View, Image, StyleSheet, PDFViewer, Font, PDFDownloadLink } from '@react-pdf/renderer';
import font1 from '../fonts/DancingScript.ttf';
import font2 from '../fonts/PlayfairDisplay.ttf';
import font3 from '../fonts/Montserrat.ttf';
import CustomButton, { CustomButton2 } from './Button';

// Create a React component for the certificate
const Certificate = ({ name, data, backgroundImageUrl, regId }) => {
  return (
    <Document pageMode="fullScreen">
      <Page  size="A4" orientation="landscape" style={styles.page}>
        <View>
            <Image src={backgroundImageUrl}  />
        </View>
            <Text style={{top: "270px", fontSize: 62, fontFamily: 'Dancing Script', color: "#004AAD", ...styles.certificateText}}>{name}</Text>
            <Text style={{top: "360px", fontSize: 13.6, marginHorizontal: '180',
                fontFamily: 'Playfair Display', ...styles.certificateText}}>{data}</Text>
            <Text style={{top: "560px", fontSize: 10, marginHorizontal: '180',
                fontFamily: 'Montserrat', ...styles.certificateText}}>{regId}</Text>
      </Page>
    </Document>
  );
};

Font.register({
    family: "Dancing Script",
    format: "truetype",
    src: font1 
});  

Font.register({
    family: "Playfair Display",
    format: "truetype",
    src: font2
});

Font.register({
    family: "Montserrat",
    format: "truetype",
    src: font3
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
  },
  centered: {
    position: 'absolute',
    top: '50%',
    left: '20%',
    transform: 'translate(-50%, -20%)',
    textAlign: 'center',
  },
  certificateText: {
    position: 'absolute', 
    fontWeight: 'bold',
    left: '0px',
    right: '0px',
    // marginHorizontal: 'auto',
    // marginHorizontal: '200',
    textAlign: "center",
    justifyContent: 'center',
  },
});

// Create a component to render multiple certificates
const CertificateGenerator = ({user, internship, isButton}) => {

    const regId = `Registration ID: ${user.batchNo}${internship.code}${user.regNo} \n`;
    const fileName = `${user.name}_certificate.pdf`;
    const doc = <Certificate name={user.name} data={internship.data} backgroundImageUrl={internship.certificate} regId={regId}/>


    if(isButton) {
        return (
        <div style={{textDecoration:'none'}}>
        <PDFDownloadLink document={doc} fileName={fileName} style={{textDecoration:'none'}}>
        {({ blob, url, loading, error }) =>
        <CustomButton>
            {loading ? 'Loading document...' : error ? "Error" :  'Download certificate'}
        </CustomButton>
        }
        </PDFDownloadLink>
        </div>
      );
    } else {
  return (
    <PDFViewer style={{ width: '100%', height: '72vw' }}>
          <Certificate name={user.name} data={internship.data} backgroundImageUrl={internship.certificate} regId={regId}/>
    </PDFViewer>
  );
    }
};

export default CertificateGenerator;
