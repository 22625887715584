import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, Alert, Container, LinearProgress } from '@mui/material';
import { Google as GoogleIcon } from '@mui/icons-material';
import { useSignup } from "../hooks/useSignup";
import CustomButton from "../components/Button";
import Loading from "../components/Loading";

export default function AdminRegistration() {
  const { signup, isPending, error } = useSignup();
  const [secretCode, setSecretCode] = useState('');

  const handleSecretCodeChange = (event) => {
    setSecretCode(event.target.value);
  };

  const handleGoogleSignUp = async () => {
    if (secretCode !== 'admin@auto') {
      alert('Invalid secret code');
      return;
    }
    signup({isAdmin: true});
  };

  return (
    <Container 
        maxWidth="lg"
        sx={{
        minHeight: { xs: "80vh", sm: "80vh", md: "81.2vh" },
        marginTop: "10px",
        marginBottom: "10px",
        // display: "flex",
        textAlign: 'center',
        alignItems: "center",
        justifyContent: "center",
        }}>
      <Typography variant="h4" gutterBottom>Admin Registration</Typography>
      <Box component="form" noValidate sx={{ mt: 3 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="secret-code"
          label="Secret Code"
          name="secret-code"
          value={secretCode}
          onChange={handleSecretCodeChange}
        />
        <CustomButton
        //   fullWidth
        //   variant="contained"
        //   sx={{ mt: 3, mb: 2 }}
          onClick={handleGoogleSignUp}
          startIcon={<GoogleIcon />}
        >
          Sign up with Google
        </CustomButton>
        {error && (
          <Alert
            severity="error"
            sx={{
              marginBottom: "2rem",
            }}
          >
            {error}
          </Alert>
        )}
        {isPending && (
          <Loading/>
        )}
      </Box>
    </Container>
  );
}

