import {
  Box,
  InputAdornment,
  TextField,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import { CustomButton2 } from "./Button";

const years = [
  { id: 1, title: "1st year" },
  { id: 2, title: "2nd year" },
  { id: 3, title: "3rd year" },
  { id: 4, title: "4th year" },
];

export default function UserForm({ back, onSubmit, buttonText, user, type }) {
  const [name, setName] = useState(user ? user.name : "");
  const [nameError, setNameError] = useState(false);
  const [phone, setPhone] = useState(user ? user.phone : "");
  const [phoneError, setPhoneError] = useState(false);
  
  const [regNo, setRegNo] = useState(user ? user.regNo : "");
  const [regNoError, setRegNoError] = useState(false);

  const [batchNo, setBatchNo] = useState(user ? user.batchNo : "");
  const [batchNoError, setBatchNoError] = useState(false);

  const [groupNo, setGroupNo] = useState(user ? user.groupNo : "");
  const [groupNoError, setGroupNoError] = useState(false);

  const [department, setDepartment] = useState(user ? user.department : "");
  const [departmentError, setDepartmentError] = useState(false);
  const [year, setYear] = useState(
    user ? years.find((year) => year.id === user.year) : years[0]
  );

  const [qualification, setQualification] = useState(user ? user.qualification ?? "" : "");
  const [qualificationError, setQualificationError] = useState(false);


  const handleSubmit = (e) => {
    e.preventDefault();
    setNameError(false);
    setPhoneError(false);
    setRegNoError(false);
    setGroupNoError(false);
    setBatchNoError(false);
    setQualificationError(false);
    setDepartmentError(false);
    if (name.length < 3) {
      setNameError(true);
      return;
    }
    if (phone.length < 10 || phone.length > 10 || (!/[0-9]/.test(phone))) {
      setPhoneError(true);
      console.log("erroir");
      return;
    }
    if (department.length < 2 && type != "pro") {
      setDepartmentError(true);
      return;
    }
    if (qualification.length < 2 && type == "pro") {
      setQualificationError(true);
      return;
    }
    if (regNo.length < 1 || (!/[0-9]/.test(regNo))) {
      setRegNoError(true);
      return;
    }
    if (groupNo.length < 1 || (!/[0-9]/.test(groupNo))) {
      setGroupNoError(true);
      return;
    }
    if (batchNo.length < 1 || (!/[0-9]/.test(batchNo))) {
      setBatchNoError(true);
      return;
    }

    onSubmit({ name, regNo, groupNo, batchNo, phone, department, year: year.id, qualification });
  };

  return (
    <Box
      component="form"
      sx={{
        maxWidth: "100vw",
        // onSubmit: handleSubmit,
        "& .MuiTextField-root": {
          display: "flex",
          margin: "0 auto",
          paddingBottom: 1,
          paddingRight: 1,
          //   p: 1,
        },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        required
        id="name"
        label="Name"
        type="text"
        helperText={"Your official name as registered in the university"}
        value={name}
        error={nameError}
        onChange={(e) => setName(e.target.value)}
      />

      <TextField
        required
        // fullWidth
        id="phone"
        label="Phone"
        type="tel"
        InputProps={{
          startAdornment: <InputAdornment position="start">+91</InputAdornment>,
        }}
        value={phone}
        error={phoneError}
        onChange={(e) => setPhone(e.target.value)}
      />

      {(type && type === 'pro') && <TextField
          required
          fullWidth
          id="qualification"
          label="Qualification"
          type="text"
          value={qualification}
          error={qualificationError}
          onChange={(e) => setQualification(e.target.value)}
        />}

      {(!type || type !== 'pro') && <Box
        sx={{
          display: "flex",
        }}
      >
       <TextField
          required
          fullWidth
          id="department"
          label="Department"
          type="text"
          helperText="eg: ECE"
          value={department}
          error={departmentError}
          onChange={(e) => setDepartment(e.target.value)}
        />
        <TextField
          id="year"
          fullWidth
          select
          label="Year of study"
          value={year}
          onChange={(e) => setYear(e.target.value)}
          // helperText="Please select your currency"
          sx={{ minWidth: "100px" }}
        >
          {years.map((option) => (
            <MenuItem key={option.id} value={option}>
              {option.title}
            </MenuItem>
          ))}
        </TextField>
      </Box> }

      
      <TextField
        required
        // fullWidth
        id="regNo"
        label="Reg no"
        type="number"
        // InputProps={{
        //   startAdornment: <InputAdornment position="start">+91</InputAdornment>,
        // }}
        value={regNo}
        error={regNoError}
        onChange={(e) => setRegNo(e.target.value)}
      />

      <TextField
        required
        // fullWidth
        id="groupNo"
        label="Group no"
        type="number"
        // InputProps={{
        //   startAdornment: <InputAdornment position="start">+91</InputAdornment>,
        // }}
        value={groupNo}
        error={groupNoError}
        onChange={(e) => setGroupNo(e.target.value)}
      />

      <TextField
        required
        // fullWidth
        id="batchNo"
        label="Batch no"
        type="number"
        // InputProps={{
        //   startAdornment: <InputAdornment position="start">+91</InputAdornment>,
        // }}
        value={batchNo}
        error={batchNoError}
        onChange={(e) => setBatchNo(e.target.value)}
      />

        

      <Box
        display="flex"
        sx={{
          paddingTop: "2rem",
        }}
      >
        <Box
          sx={{
            margin: "0 auto",
            marginBottom: "20px",
          }}
          >
        {back && (
          <CustomButton2
            onClick={back}
          >
            Back
          </CustomButton2>
        )}
        
        </Box>
        <Box
          sx={{
            margin: "0 auto",
            marginBottom: "20px",
          }}
          >
        <CustomButton2
          onClick={handleSubmit}
        >
          {buttonText ?? "Update"}
        </CustomButton2>
        </Box>
      </Box>
    </Box>
  );
}
