import { useEffect, useState } from "react";
import { db } from "../firebase/config";
import { collection, query, where, onSnapshot, doc, getDocs } from "firebase/firestore";

export const useDocument = (c, id, q = false) => {
  const [document, setDocument] = useState(null);
  const [error, setError] = useState(null);

  // realtime document data
  useEffect(() => {
    if (id) {
      const ref = doc(db, c, id);
      const unsubscribe = onSnapshot(
        ref,
        (snapshot) => {
          // need to make sure the doc exists & has data
          if (snapshot.data()) {
            setDocument({ ...snapshot.data(), id: snapshot.id });
            setError(null);
          } else {
            setError("No such document exists");
          }
        },
        (err) => {
          console.log(err.message);
          setError("Failed to get document");
        }
      );

      // unsubscribe on unmount
      return () => unsubscribe();
    } 

    else if (q) {
      // console.log(q);
      const fetchDocument = async () => {
        // try {
            const collectionRef = collection(db, c);
            const queryRef =
              typeof q === "string"
                ? query(collectionRef, where("email", "==", q))
                : query (collectionRef, q);
  
            const snapshot = await getDocs(queryRef);
  
            if (!snapshot.empty) {
              const newDocuments = snapshot.docs.map((doc) => doc.data());
              const docData = newDocuments[0];
              setDocument({ ...docData, id: snapshot.id });
              setError(null);
            } else {
              setError("No such document exists");
            }
        // } catch (err) {
        //   // console. log(err);
        //   setError("Failed to get document");
        // }
      };
  
      fetchDocument();

    }
    else {
      setError("No id or query param");
    }
  }, [c, id, q]);

  return { document, error };
};
