import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";
import MenuIcon from "@mui/icons-material/Menu";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CloseIcon from "@mui/icons-material/Close";
import Settings from "@mui/icons-material/Settings";
import {
  Tooltip,
  IconButton,
  Menu,
  Divider,
  MenuItem,
  ListItemIcon,
  Box,
  Button,
  Avatar
} from "@mui/material";
import React, { useState } from "react";

import { useLogin } from "../hooks/useLogin";
import { useLogout } from "../hooks/useLogout";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { Fragment } from "react";
import { CustomButton2 } from "./Button";

const navigationLinks = [
  { name: "Home", href: "/" },
  { name: "About us", href: "/about" },
  { name: "Contact us", href: "/contact" },
];

export default function Header() {
  const [open, setOpen] = useState(false);

  const { user } = useAuthContext();
  const { login } = useLogin();
  
  const location = useLocation();
  const path = location.pathname;

  return (
    <AppBar elevation={0} color="secondary">
      <Container maxWidth="lg">
        <Toolbar
          disableGutters
          sx={{
            height: 80,
            display: "flex",
          }}
        >
          <Logo />
         <Hidden mdDown>
            {navigationLinks.map((link) => (
              <NavLink
                key={link.href}
                link={link.href}
                name={link.name}
                path={path}
              />
            ))}
          </Hidden>
          <Hidden mdUp>
            <IconButton onClick={() => setOpen(true)}>
              <MenuIcon color="primary" />
            </IconButton>
          </Hidden>
          <Hidden mdDown>
            <Box width={20}></Box>
          </Hidden>
          {!user && (
            <CustomButton2
              onClick={login}
            >
              Login
              {/* {isPending ? "Loading.." : "Login"} */}
            </CustomButton2>
          )}
          {user && <AccountMenu user={user} />}
 
        </Toolbar>
      </Container>

      <SwipeableDrawer
        anchor="right"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: "background.default",
          },
        }}
      >
        <div>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {navigationLinks.map((link) => (
            //todo add background colour on particular pages
            <ListItem key={link.href}>
              <NavLink name={link.name} link={link.href} path={path} />
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
    </AppBar>
  );
}

function Logo() {
  const navigate = useNavigate();
  return (
    <Box
      component="img"
      onClick={() => navigate("/")}
      sx={{
        marginRight: "auto",
        cursor: "pointer",
        // width: "100%",
        maxWidth: { xs: "120px", sm: "120px", md: "150px" },
        objectFit: "cover",
      }}
      alt="Autobonics logo"
      src={require("../images/logo.png")}
    />
  );
}

export function NavLink({ name, link, path, isFooter, isSmall }) {
  const navigate = useNavigate();
  return (
    <Typography
      onClick={() => navigate(link)}
      color={isFooter ? "secondary" : "primary"}
      align={isFooter ? (isSmall ? "left" : "right") : "center"}
      variant="h6"
      sx={{
        marginLeft: 4,
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: "regular",
        textAlign: "bottom",
        borderBottom: path === link ? "3px solid #009DEB" : "none",
        ":hover": {
          borderBottom: !isFooter
            ? (theme) => `3px solid ${theme.palette.primary.main}`
            : "none",
        },
      }}
    >
      {name}
    </Typography>
  );
}

function AccountMenu({ user }) {
  // const navigate = useNavigate();
  const { logout, logoutIsPending } = useLogout();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Fragment>
      <Tooltip title="Account">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar alt={user.displayName} src={user.photoURL} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            padding: "14px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 60,
              height: 60,
              margin: "0 auto",
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Avatar
          alt={user.displayName}
          src={user.photoURL}
          sx={{
            width: "100px",
            height: "100px",
          }}
        />

        <Typography
          variant="h6"
          color="text.primary"
          fontWeight="500"
          textAlign="center"
          sx={{
            paddingTop: "0.5rem",
          }}
        >
          {user.displayName}
        </Typography>

        <Typography variant="p" color="text.primary" textAlign="center">
          {user.email}
        </Typography>

        <Divider
          sx={{
            paddingTop: "1rem",
          }}
        />

        <MenuItem
          onClick={() => {
            // navigate("/settings");
          }}
          sx={{
            marginTop: "8px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ListItemIcon
            sx={{
              color: "text.primary",
              marginRight: -1,
            }}
          >
            <Settings />
          </ListItemIcon>
          Settings
        </MenuItem>

        <Divider />
        <Box height={8} />

        <Button
          variant="contained"
          onClick={logout}
          disabled={logoutIsPending}
          sx={{
            margin: "0 auto",
            display: "block",
            // width: "100%",
          }}
        >
          Logout
        </Button>
      </Menu>
    </Fragment>
  );
}
