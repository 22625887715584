import { initializeApp } from "firebase/app";
import { getFirestore, Timestamp } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAVzvJRDo-ISmBLdTMrE8K0H17Yh0CtTeU",
  authDomain: "autobonics-internship.firebaseapp.com",
  projectId: "autobonics-internship",
  storageBucket: "autobonics-internship.appspot.com",
  messagingSenderId: "932811903332",
  appId: "1:932811903332:web:6996a0616d5109ab516a71",
  measurementId: "G-HSXV17MHPC"
};

// init firebase
initializeApp(firebaseConfig);

// init services
const db = getFirestore();
const auth = getAuth();
const storage = getStorage();

// timestamp
const timestamp = Timestamp;

export { db, auth, storage, timestamp };
