
import {Alert, AlertTitle} from "@mui/material";
export default function CustomAlert({
    alertOpen,
    alertSeverity,
    handleAlertClose,
    alertMessage,
}) {
  return (
    <>
    {alertOpen && <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 9999 }}>
        <Alert severity={alertSeverity} onClose={handleAlertClose} open={alertOpen}>
        <AlertTitle>{alertSeverity === 'success' ? 'Success' : 'Error'}</AlertTitle>
        {alertMessage}
        </Alert>
        </div>
    }
    </>
  );
}
