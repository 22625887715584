// import LinearProgress from "@mui/material/Button";
// export default function Loading({
// }) {
//   return (
//     <LinearProgress
//     sx={{
//         position: "absolute",
//         // top: { xs: "58px", sm: "64px", md: "64px" },
//         top: { xs: "80px", sm: "80px", md: "80px" },
//         left: 0,
//         right: 0,
//         zIndex: 1,
//       }}
//     >
//     </LinearProgress>
//   );
// }

import { LinearProgress } from '@mui/material';

const CustomLinearProgress = () => {
  return (
    <LinearProgress
      sx={{
        position: "absolute",
        top: { xs: "96px", sm: "80px", md: "80px" },
        left: 0,
        right: 0,
        zIndex: 1,
      }}
    />
  );
}

export default CustomLinearProgress;