import React from 'react';
import {
  PDFDownloadLink,
  Document,
  Font,
  Page,
  Text,
  Image,
  StyleSheet,
  View,
  Link,
  PDFViewer,
} from '@react-pdf/renderer';

import { useLocation } from 'react-router-dom';
import { useDocument } from "../hooks/useDocument";
import { useCollection } from "../hooks/useCollection";

import Hidden from "@mui/material/Hidden";
import { Container, Typography, Alert, Box } from '@mui/material';
import CustomLinearProgress from '../components/Loading';
import CustomButton2 from '../components/Button';
import CertificateGenerator from '../components/Certificate';


const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    position: 'relative',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald',
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  textSmall: {
    margin: 0,
    padding: 0,
    fontSize: 12,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  logoImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    // marginVertical: 15,
    height: 100,
    width: 100,
    // marginHorizontal: 100,
  },
  headerImage: {
    marginBottom: 20,
    marginHorizontal: 20,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  watermarkImage: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
    width: 500,
    height: 500,
    opacity: 0.2,
    // Add other styles for the watermark image here
  },
  indent: {
    marginLeft: 20, // Adjust the value as needed for the desired indentation
  },
});

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const Subtitle = ({ children, ...props }) => (
  <Text style={styles.subtitle} {...props}>
    {children}
  </Text>
);

const baseUrl = window.location.origin;

const Header = ({ user, internship }) => (
  <Page style={styles.body} wrap>

    {/* <Image
        style={styles.logoImg}
        src={baseUrl + '/logo512.png'}
      /> */}
    <Link src="https://education.bonic.ai/">
      <Text style={styles.header} fixed>
        Bonic Education Program Particpant Report
      </Text>
    </Link>
    <Text style={styles.title}>Bonic Education Program Participation Report</Text>
    <Text style={styles.author}>{user.name}</Text>
    <Image
      style={styles.headerImage}
      src={baseUrl + '/logo.jpg'}
    />
    <Subtitle>
      Program Details:
    </Subtitle>
    <Text style={styles.text}>
      {`Bonic Education Program: ${internship.name} \n`}
      {/* {`Duration: ${internship.period}`} - {internship.start} to {internship.end} */}
      {`Duration: ${internship.period}`}
    </Text>
    <Subtitle>
      Student Information:
    </Subtitle>
    <Text style={styles.text}>
      {`Name: ${user.name} \n`}
      {`Student ID: ${user.batchNo}${internship.code}${user.regNo} \n`}
      {`Institution: ${user.institutionName} \n`}
      {`Department: ${user.department} \n`}
    </Text>
    {/* <Subtitle break>
        Internship Overview:
      </Subtitle>
      <Text style={styles.text}>
        During the internship program, {user.name} actively participated in various activities and gained valuable knowledge and skills in the following areas:
      </Text>
      <Text style={styles.text}>
        {`1. Mechanical Design:\n\n- Demonstrated proficiency in CAD tools for 3D modeling\n- Designed complex mechanical structures for robotics projects\n\n`}
        {`2. 3D Printing:\n\n- Operated and maintained 3D printers with precision\n- Produced high-quality 3D prints for project requirements\n\n`}
        {`3. Robotics:\n\n- Demonstrated understanding of robotics principles and components\n- Integrated sensors and actuators to create functional robots\n\n`}
        {`4. Programming:\n\n- Developed programming skills using Python and Arduino\n- Programmed robots to perform specific tasks and movements\n\n`}
        {`5. Project Work:\n\n- Actively participated in team-based projects from concept to completion\n- Collaborated effectively with team members, contributing to project success\n\n`}
        {`6. Problem-Solving:\n\n- Showed excellent problem-solving skills in overcoming technical challenges\n- Applied critical thinking to troubleshoot and optimize project outcomes\n\n`}
        {`7. Communication and Collaboration:\n\n- Engaged in effective communication within the team and with mentors\n- Demonstrated teamwork and collaboration skills during group projects`}
      </Text> */}
    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      fixed
    />
  </Page>
);

const Footer = ({ user, attendance, totalUpdates, avgRating, score }) => (
  <Page style={styles.body} wrap>
    <Link src="https://internship.autobonics.com/">
      <Text style={styles.header} fixed>
        Bonic Education Program Particpant Report
      </Text>
    </Link>
    <Subtitle>
      Overall Assessment:
    </Subtitle>
    <Text style={styles.text}>
      {`Attendance: ${attendance} \n`}
      {`Total updates: ${totalUpdates} \n`}
      {`Rating: ${avgRating}\n`}
      {`Score: ${score} \n`}
    </Text>
    <Text style={styles.text}>
      {`${user.name} exhibited enthusiasm, dedication, and a strong commitment to learning throughout the program. Their active participation, positive attitude, and willingness to take on new challenges were commendable.\n\nWe believe that the skills and knowledge acquired by ${user.name} during the program will contribute significantly to their personal and professional growth in the field of robotics and engineering.`}
    </Text>
    <Subtitle>
      PDF Generation:
    </Subtitle>
    <Text style={styles.text}>
      {`Attached to this report is a personalized PDF document that includes detailed information about ${user.name}'s participation in the program. The PDF report encompasses their daily updates, project contributions, and individual assessments.\n\nWe are confident that this comprehensive report will serve as a valuable resource for ${user.name} to reflect on their progress and showcase their accomplishments.\n\nShould you have any questions or require further information, please feel free to reach out to us.\n\nSincerely,\n`}
      <Text style={styles.text}>
        {`
    Cheif Operating Officer\nAutobonics Private Limited\nAddress: 2nd floor, Ariyattil building, Chakkaraparambu, Kerala 682028\nPhone: +91 8848668847\nEmail: autobonics@gmail.com\nWebsite: education.bonic.ai
    `}
      </Text>
    </Text>
    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      fixed
    />
  </Page>
);




const ResumeDocument = ({ header, footer, updates }) => (
  <Document>
    {header}
    <Page style={styles.body} wrap>
      {/* <Image
        style={styles.logoImg}
        src={baseUrl + '/logo512.png'}
      /> */}
      {/* Watermark */}
      {/* <Image
          src={baseUrl + '/logo512.png'}
          style={styles.watermarkImage}
        /> */}
      <Link src="https://education.bonic.ai/">
        <Text style={styles.header} fixed>
          Bonic Education Program Particpant Report
        </Text>
      </Link>
      <Text style={styles.title}>Projects</Text>
      {/* <Text style={styles.author}>Miguel de Cervantes</Text> */}
      {/* <UpdatesListPdf updates={updates}/> */}
      {updates ?
        updates.reverse().map((update, index) => {
          const date = update.date.toDate(); // Convert Firestore Timestamp to JavaScript Date
          const timeOptions = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            hourCycle: 'h12',
          }; // Set time options

          update.formatedDate = `${date.toLocaleDateString()} ${date.toLocaleTimeString([], timeOptions)}`;
          return (
            <View key={index}>
              <Subtitle>{index + 1}. {update.formatedDate} </Subtitle>
              <Text style={styles.text}>Rating: {update.rating}</Text>
              <Image style={styles.image} src={update.fileUrl} />
              <Text style={styles.text}>{update.description}</Text>
            </View>
          );
        }) : ""
      }
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
    {footer}
  </Document>
);


function PdfGen({ user, internship, updates }) {
  // console.log(updates);

  const uniqueDates = new Set();
  updates.forEach((update) => {
    const dateString = update.date.toDate().toISOString().split("T")[0]; // Convert date to string in format "YYYY-MM-DD"
    uniqueDates.add(dateString);
  });
  const totalDays = uniqueDates.size;
  // console.log(`Total Days: ${totalDays}`);

  const sumOfRatings = updates.reduce((total, update) => total + update.rating, 0);
  // console.log("Total updates:", updates.length);
  // console.log("Sum of ratings:", sumOfRatings);
  const averageRating = sumOfRatings / updates.length;
  // console.log("Avg of ratings:", averageRating);
  const ratingWeightage = 5; //50% weightage to the rating.
  const score = averageRating * ratingWeightage + totalDays;
  // console.log(`Score = ${score}`);
  const total = 5 * ratingWeightage + internship.periodInDays;
  // console.log(`Total = ${total}`);


  const fileName = `${user.name}_report.pdf`;

  const doc = <ResumeDocument
    header={<Header user={user} internship={internship} />}
    footer={<Footer user={user} attendance={totalDays} score={`${score.toFixed(2)}/${total}`} avgRating={`${averageRating.toFixed(2)}/5`} totalUpdates={updates.length} />}
    user={user} internship={internship} updates={updates} />

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

      <Typography
        variant="h4"
        color="text.primary"
        textAlign="center"
        sx={{
          paddingTop: "0.8rem",
        }}
      >
        Internship Program Particpant Report
      </Typography>

      <Typography
        variant="h5"
        color="text.primary"
        fontWeight="700"
        textAlign="center"
        sx={{
          paddingTop: "2rem",
        }}
      >
        {`Name: ${user.name} \n`}
      </Typography>
      <Typography
        variant="h6"
        color="text.primary"
        fontWeight="500"
        textAlign="center"
      >
        {`Student ID: ${user.batchNo}BA23IN2R${user.regNo} \n`}
      </Typography>
      <Typography
        variant="h6"
        color="text.primary"
        fontWeight="500"
        textAlign="center"
      >
        {`Institution: ${user.institutionName} \n`}
      </Typography>
      <Typography
        variant="h6"
        color="text.primary"
        fontWeight="500"
        textAlign="center"
      >
        {`Department: ${user.department} \n`}
      </Typography>

      <Typography
        variant="h5"
        color="text.primary"
        fontWeight="700"
        textAlign="center"
        sx={{
          paddingTop: "0.5rem",
        }}
      >
        Overall Assessment:
      </Typography>
      <Typography
        variant="h6"
        color="text.primary"
        fontWeight="500"
        textAlign="center"
      >
        {`Attendance: ${totalDays} \n`}
      </Typography>
      <Typography
        variant="h6"
        color="text.primary"
        fontWeight="500"
        textAlign="center"
      >
        {`Total updates: ${updates.length} \n`}
      </Typography>
      <Typography
        variant="h6"
        color="text.primary"
        fontWeight="500"
        textAlign="center"
      >
        {`Rating: ${averageRating.toFixed(2)}/5\n`}
      </Typography>
      <Typography
        variant="h6"
        color="text.primary"
        fontWeight="500"
        textAlign="center"
      >
        {`Score: ${score.toFixed(2)}/${total} \n`}
      </Typography>
      {/* <Hidden mdDown>
        <PDFViewer style={{ width: '100vh', height: '8  0vh', marginTop: '10px' }}>
          {doc}
        </PDFViewer>
      </Hidden> */}

      <PDFDownloadLink document={doc} fileName={fileName}>
        {({ blob, url, loading, error }) =>
          <CustomButton2>
            {loading ? 'Loading document...' : error ? "Error" : 'Download report'}
          </CustomButton2>
        }
      </PDFDownloadLink>

      <CertificateGenerator user={user} internship={internship} isButton={true} />
    </div>
  );
};

function UpdatesGetter({ user }) {
  // console.log(user);

  const queryC1 = ["userId", "==", user.uid];
  const orderByC = ["date", "desc"];
  const limitC = 50;
  let queryC2 = ["isVerified", "==", true];//todo uncomment and add to bellow line
  const { error: updateError, documents: updates, totalDocuments } = useCollection("updates", queryC1, queryC2, orderByC, limitC);

  // console.log(user.internshipId);
  //Getting internship program
  const { document: internship, error } = useDocument("internships", user.internshipId);

  return (
    <>
      {(!updates || !internship) && <CustomLinearProgress />}
      {(error || updateError) &&
        <Alert
          severity="error"
          sx={{
            marginBottom: "2rem",
          }}
        >
          {error ?? updateError}
        </Alert>
      }
      {
        updates && internship &&
        <PdfGen user={user} internship={internship} updates={updates} />
      }

      {/* {
          internship &&
          <CertificateGenerator user={user} internship={internship} isButton={true}/>
        } */}



    </>
  );
};


export default function Report() {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userEmail = searchParams.get('email');

  // console.log(userEmail);

  const { document: user, error } = useDocument("users", null, userEmail);


  return (
    <Container
      maxWidth="lg"
      sx={{
        minHeight: { xs: "80vh", sm: "80vh", md: "81.2vh" },
        // marginTop: "10px",
        // marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >

      {!user && <CustomLinearProgress />}

      {error &&
        <Alert
          severity="error"
          sx={{
            marginBottom: "2rem",
          }}
        >
          {error}
        </Alert>
      }

      {user && <UpdatesGetter user={user} />}

    </Container>
  );
};