import { useState, useRef } from 'react';
import { Container, TextField, Alert, IconButton, ButtonBase, Box } from '@mui/material';
import CustomLinearProgress from '../components/Loading';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, doc, setDoc, updateDoc, increment } from 'firebase/firestore';
import { useAuthContext } from '../hooks/useAuthContext';
import { db, storage, timestamp } from '../firebase/config';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import { useDocument } from "../hooks/useDocument";
import UpdatesList from '../components/Updates';
import CustomAlert from '../components/CustomAlert';
import CustomButton2 from '../components/Button';
import { useNavigate } from 'react-router-dom';
import CertificateGenerator from '../components/Certificate';


function DailyWorkPage() {
  const { user: currentUser } = useAuthContext();
  const { document: user, error } = useDocument("users", currentUser.uid);


  return (
    <Container 
        maxWidth="lg"
        sx={{
        minHeight: { xs: "80vh", sm: "80vh", md: "81.2vh" },
        // marginTop: "10px",
        // marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        }}
    >

        { error && 
          <Alert
            severity="error"
            sx={{
              marginBottom: "2rem",
            }}
          >
            {error}
          </Alert>
        }

        {user && <UpdateWidget user={user}/>}
       
    </Container>
  );
}

export default DailyWorkPage;

function UpdateWidget({user}){
  const { document: internship, error } = useDocument("internships", user.internshipId);

  function isCompleted(){
    // const today = new Date();
    // const diff = today.getTime() - user.joinedOn.toDate().getTime();
    // const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
    // console.log(days);
    // return internship && internship.periodInDays < days;
    console.log(internship && !internship.isActive);
    return internship && !internship.isActive;
  }

  const [work, setWork] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertOpen, setAlertOpen] = useState(false);
  
  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  
  const handleWorkChange = (event) => {
    setWork(event.target.value);
  };

  const handleFileChange = (file) => {
    console.log("FIle change");
    // const file = event.target.files[0];
    // setFile(file);
    if (file && file.type.startsWith('image/') && file.size <= 1500000) {
      setFile(file);
      setAlertMessage(`${file.name} File selected!`);
      setAlertSeverity("success");
      setAlertOpen(true);
    } else {
      setAlertMessage("Please select a valid image file (less than 1.5MB)");
      setAlertSeverity("error");
      setAlertOpen(true);
      setFile(null); // reset the file input
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    setAlertOpen(false);

    // Validate the input and file
    if (!work) {
      setAlertMessage("Please provide a description of the work done.");
      setAlertSeverity("error");
      setAlertOpen(true);
      return;
    }
    if (work.length<10) {
        setAlertMessage("Please provide a description with more than 3 words.");
        setAlertSeverity("error");
        setAlertOpen(true);
        return;
      }
    if (!file) {
      setAlertMessage("Please select a file to upload.");
      setAlertSeverity("error");
      setAlertOpen(true);
      return;
    }
    if (!file.type.startsWith('image/')) {
      setAlertMessage("Please select an image file.");
      setAlertSeverity("error");
      setAlertOpen(true);
      return;
    }
    if (file.size > 1500000) {
      setAlertMessage("File size must be less than 1.5MB");
      setAlertSeverity("error");
      setAlertOpen(true);
      return;
    }

    setLoading(true);

    try {
        const updatesRef = collection(db, 'updates');
        const newDocRef = doc(updatesRef); // create a new document reference with a unique ID
        const newDocId = newDocRef.id; // get the unique ID of the new document
        console.log(newDocId);
        // Upload the file to Firebase Storage
        const storageRef = ref(storage, `updates/${newDocId}`);
        await uploadBytes(storageRef, file);
        const downloadUrl = await getDownloadURL(storageRef);
        
        // Save the work and file URL to Firestore
        const createdAt = timestamp.now();
        await setDoc(newDocRef, {
            id: newDocId,
            userId: user.uid,
            description: work,
            fileUrl: downloadUrl,
            date: createdAt,
            isEditRequested: false,
            isVerified: false,
        });

        const userRef = doc(db, "users", user.uid);
        await updateDoc(userRef, {
          updatesCount: increment(1),
        });

        const teamRef = doc(db, "teams", `${user.groupNo}`);
        await updateDoc(teamRef, {
          updatesCount: increment(1),
        });

        setAlertMessage(`Work update successfully submitted with ID: ${newDocRef.id}.`);
        setAlertSeverity("success");
        setAlertOpen(true);
        setFile(null);
        setWork("");
    } catch (error) {
        console.log(error);
        setAlertMessage("An error occurred. Please try again.");
        setAlertSeverity("error");
        setAlertOpen(true);
    }

    // Clear the form inputs
    setWork('');
    setFile(null);
    setLoading(false);
  };

  const navigate = useNavigate();
  const handleReportGen = (user) => {
    navigate(`/report?email=${user.email}`);
  };

  return (
    <>
        {loading && <CustomLinearProgress/>}

        { error && !user.isAdmin &&
          <Alert
            severity="error"
            sx={{
              marginBottom: "2rem",
            }}
          >
            {error}
          </Alert>
        }



          {!isCompleted() && <>
            {/* <Box sx={{ height: "100px" }} /> */}
            <UpdatesList user={user}/>
            <Box sx={{ height: { xs: "800px", sm: "800px", md: "800px" } }} />
          </>}


        {isCompleted() && !user.isAdmin &&
          <CertificateGenerator user={user} internship={internship}/>
        }

    
       
        <div style={{ position: "fixed", bottom: 0, width: "100%", backgroundColor: "#f2f2f2", padding: '10px' }}>

        {isCompleted() && !user.isAdmin &&
        <Box display="flex" justifyContent="center">
          <CustomButton2   onClick={() => handleReportGen(user)}>
            Generate report
          </CustomButton2>
        </Box>
        }
      
        {!error && user.internshipName && !isCompleted() && 
          <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                label="Today's update"
                multiline
                rows={4}
                fullWidth
                value={work}
                onChange={handleWorkChange}
                margin="normal"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <FileInputButton onFileSelected={handleFileChange} file={file} />
                  ),
                  sx: { backgroundColor: "white" }
                }}
              />
            {/* <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} /> */}
            {/* <input type="file" onChange={handleFileChange} style={{ display: 'none' }} /> */}
            {/* <IconButton onClick={() => document.querySelector('input[type=file]').click()}>
              <AttachFileIcon />
            </IconButton> */}
            <IconButton type="submit">
              <SendIcon />
            </IconButton>
          </form>
        }
      
    </div>

    <CustomAlert alertOpen={alertOpen} alertSeverity={alertSeverity} handleAlertClose={handleAlertClose} alertMessage={alertMessage}/>

    </>
  );
}


function FileInputButton(props) {
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    props.onFileSelected(file);
  };

  return (
    <>
      <ButtonBase onClick={handleClick} color="#009DEB" disabled={props.file != null}>
        <AttachFileIcon color={props.file == null ? "primary" : "secondary  "}/>
        {/* <Typography variant="button">Select File</Typography> */}
      </ButtonBase>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileInputChange}
        style={{ display: 'none' }}
      />
    </>
  );
}


