import {
  Grid,
  Container,
  Box,
  Avatar,
  Alert,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
// import Paid from "@mui/icons-material/Paid";
// import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

import { useEffect, useState } from "react";
import { useCollection } from "../hooks/useCollection";
import { useDocument } from "../hooks/useDocument";
import { useAuthContext } from "../hooks/useAuthContext";
// import { useFirestore } from "../hooks/useFirestore";
import { increment } from "firebase/firestore";
import DropShadowBox from "../components/DropShadowBox";
import { useNavigate } from 'react-router-dom';
import CustomLinearProgress from '../components/Loading';

export default function UsersView() {
  // const { user: currentUser } = useAuthContext();
  // const { document: user, error } = useDocument("users", currentUser.uid);

  const queryC = null; //["isMember", "==",  false];
  const orderByC = ["joinedOn", "desc"];

  const { documents, error: usersError } = useCollection("users", queryC, null, orderByC, null);

  const navigate = useNavigate();

  const handleReportGen = (user) => {
    navigate(`/report?email=${user.email}`);
  };

  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  return (
    <Container
      maxWidth="lg"
      sx={{
        minHeight: { xs: "80vh", sm: "80vh", md: "81.2vh" },
        marginTop: "10px",
        // marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box>
        {(usersError) && (
          <Alert
            severity="error"
            sx={{
              marginBottom: "2rem",
            }}
          >
            {usersError}
          </Alert>
        )}
        {(!documents || documents.lenght == 0) && <CustomLinearProgress />}
        <DropShadowBox
          margin="30px"
        //  padding="10px"
        >
          {documents && <Typography
            variant="h4"
            align="left"
            sx={{
              color: "text.secondary",
              // marginBottom: "1rem",
            }}
          >
            {`All users: ${documents != null ? documents.length : 0}`}
          </Typography>}
          {/* <Typography
            variant="h6"
            align="center"
            sx={{
              color: "text.secondary",
              marginBottom: "1rem",
            }}
          >
            Count: 0
          </Typography> */}
        </DropShadowBox>

        {documents && (
          <Grid container spacing={2}>
            {documents.map((document, index) => (
              <DropShadowBox padding="2px" key={document.uid} margin="8px">
                <ListItem
                  sx={{
                    borderRadius: "20px",
                    transition: 'background-color 0.3s',
                    '&:hover': {
                      borderRadius: "20px",
                      backgroundColor: '#e0e0e0',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => handleReportGen(document)}
                  alignItems="flex-start"
                // secondaryAction={
                //   <Box display="flex">
                //     <IconButton
                //     margin="10px"
                //       aria-label="comment"
                //       onClick={() => {
                //         window.open(
                //           `https://wa.me/91${document.phone}?text=https://chat.whatsapp.com/DmeTeEgjwUHExmVAXvWvYA`,
                //           "_blank"
                //         );
                //       }}
                //     >
                //       <WhatsAppIcon
                //         sx={{
                //           color: "black",
                //         }}
                //       />
                //     </IconButton>
                //     {/* {registrations && (
                //       <PaymentMade
                //         user={user}
                //         participant={document}
                //         registrations={registrations}
                //         event={event}
                //       />
                //     )} */}
                //   </Box>
                // }
                >
                  <ListItemIcon>
                    <Avatar
                      alt={document.displayName}
                      src={document.photoURL}
                    />
                  </ListItemIcon>

                  <ListItemText
                    primary={`${index + 1}. ${document.name}`}
                    secondary={
                      <Box
                        component={"span"}
                        sx={{
                          display: "flex",
                          // alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Phone no: {document.phone}
                        </Typography>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.secondary"
                        >
                          {`Dep: ${document.department}, Year: ${document.year}`}
                        </Typography>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.secondary"
                        >
                          {`Joined on: ${document.joinedOn.toDate().toLocaleDateString("en-IN")}`}
                        </Typography>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.secondary"
                        >
                          College: {document.institutionName}
                        </Typography>
                      </Box>
                    }
                  />
                </ListItem>

                {/* <Divider
                  variant="inset"
                  component="li"
                  sx={{
                    backgroundColor: "#D9FFFFFF",
                  }}
                /> */}
              </DropShadowBox>
            ))}
          </Grid>
        )}
      </Box>
    </Container>
  );
}

// function PaymentMade({ participant, registrations, user, event }) {
//   const [paymentMade, setPaymentMade] = useState(false);
//   const [registration, setRegistration] = useState(null);

//   useEffect(() => {
//     registrations.forEach((registration) => {
//       if (registration.email === participant.email) {
//         setRegistration(registration);
//         setPaymentMade(true);
//       }
//     });
//   }, [participant, registrations]);

//   const [isCoordinator, setIsCoordinator] = useState(false);
//   const [isCoordinatorParticipant, setIsCoordinatorParticipant] =
//     useState(false);

//   useEffect(() => {
//     if (event && event.coordinators.indexOf(user.email) > -1) {
//       setIsCoordinator(true);
//     }
//     if (event && event.coordinators.indexOf(participant.email) > -1) {
//       setIsCoordinatorParticipant(true);
//     }
//   }, [event, user, participant]);

//   // dialoge
//   const [open, setOpen] = useState(false);

//   const handleClickOpen = () => {
//     if (isCoordinator) setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   //delete regisration
//   const [loading, setLoading] = useState(false);

//   const [isCancelled, setIsCancelled] = useState(false);

//   const { updateDocument: updateUser, response: userResponse } =
//     useFirestore("users");
//   const { updateDocument: updateEvent, response: eventResponse } =
//     useFirestore("events");
//   const {
//     deleteDocument: deleteEventRegistration,
//     response: eventRegistrationResponse,
//   } = useFirestore(`events/${event.id}/registrations`);

//   const {
//     updateDocument: updateEventRegistration,
//     response: updateRegistrationResponse,
//   } = useFirestore(`events/${event.id}/registrations`);

//   // function
//   const cancelRegistration = async () => {
//     setLoading(true);
//     await deleteEventRegistration(participant.email);
//     await updateEvent(event.id, {
//       verifiedRegCount: increment(-1),
//     });
//     await updateUser(participant.uid, {
//       isMember: false,
//       membershipPeriod: null,
//       activeEvents: null,
//     });
//     setLoading(false);

//     if (
//       userResponse.error ||
//       eventResponse.error ||
//       eventRegistrationResponse.error
//       // || orderResponse.error
//     ) {
//       setError(
//         userResponse.error ??
//           eventResponse.error ??
//           eventRegistrationResponse.error
//         //  ?? orderResponse.error
//       );
//     }

//     handleClose();
//     setSnackbarOpen(true);
//     setIsCancelled(true);
//   };

//   const enrollParticipant = async () => {
//     setLoading(true);
//     await updateEventRegistration(participant.email, {
//       isParticipant: true,
//       enrollParticipantOn: new Date(),
//     });
//     await updateEvent(event.id, {
//       participants: increment(1),
//     });
//     await updateUser(participant.uid, {
//       participations: increment(1),
//       events: increment(1),
//     });
//     setLoading(false);

//     if (
//       userResponse.error ||
//       eventResponse.error ||
//       eventRegistrationResponse.error ||
//       updateRegistrationResponse.error
//     ) {
//       setError(
//         userResponse.error ??
//           eventResponse.error ??
//           eventRegistrationResponse.error ??
//           updateRegistrationResponse.error
//       );
//     }

//     handleClose();
//     setSnackbarOpen(true);
//     setIsCancelled(true);
//   };

//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [error, setError] = useState(null);

//   const handleSnackbarClose = (event, reason) => {
//     if (reason === "clickaway") {
//       return;
//     }

//     setSnackbarOpen(false);
//   };

//   return (
//     <>
//       {paymentMade &&
//         !isCancelled &&
//         !(registration && registration.isParticipant) && (
//           <IconButton aria-label="comment" onClick={handleClickOpen}>
//             <Paid
//               sx={{
//                 color: "green",
//               }}
//             />
//           </IconButton>
//         )}

//       {registration && registration.isParticipant && (
//         <CheckCircleOutlineIcon
//           sx={{
//             margin: "0.5rem",
//             color: "red",
//           }}
//         />
//       )}

//       {isCoordinatorParticipant && (
//         <VerifiedUserIcon
//           sx={{
//             margin: "0.5rem",
//             color: "green",
//           }}
//         />
//       )}

//       <Dialog open={open} onClose={handleClose}>
//         <DialogTitle>Payment details</DialogTitle>
//         <DialogContent>
//           {registration && (
//             <>
//               <DialogContentText>
//                 Registration ID: {registration.regId}
//               </DialogContentText>
//               <DialogContentText>
//                 Transacton ID: {registration.txnId}
//               </DialogContentText>
//               <Button
//                 variant="contained"
//                 onClick={cancelRegistration}
//                 disabled={loading}
//                 sx={{
//                   backgroundColor: "white",
//                   color: "black",
//                   "&:hover": {
//                     backgroundColor: "black",
//                     color: "white",
//                   },
//                   // margin: "0 auto",
//                   marginTop: "1rem",
//                   display: "flex",
//                   // width: "100%",
//                 }}
//               >
//                 {loading ? "Loading.." : "Cancel registration"}
//               </Button>

//               <Button
//                 variant="contained"
//                 onClick={enrollParticipant}
//                 disabled={loading}
//                 sx={{
//                   backgroundColor: "white",
//                   color: "black",
//                   "&:hover": {
//                     backgroundColor: "black",
//                     color: "white",
//                   },
//                   // margin: "0 auto",
//                   marginTop: "1rem",
//                   display: "flex",
//                   // width: "100%",
//                 }}
//               >
//                 {loading ? "Loading.." : "Enroll participant"}
//               </Button>
//             </>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={handleClose}
//             sx={{
//               color: "white",
//             }}
//           >
//             Cancel
//           </Button>
//           {/* <Button
//             // disabled={userResponse.isPending || hostelResponse.isPending}
//             onClick={save}
//             sx={{
//               color: "white",
//             }}
//           >
//             Enroll
//           </Button> */}
//         </DialogActions>
//       </Dialog>
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={3000}
//         onClose={handleSnackbarClose}
//       >
//         <Alert
//           onClose={handleSnackbarClose}
//           severity={error ? "error" : "success"}
//           sx={{ width: "100%" }}
//         >
//           {error ? error : "Completed!"}
//         </Alert>
//       </Snackbar>
//     </>
//   );
// }
