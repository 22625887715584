import {
    Alert,
    Typography,
    List,
    ListItem,
    ListItemText,
    Avatar,
    Rating,
    Box,
  } from '@mui/material';
  
import CustomLinearProgress from './Loading';

import CustomAlert from './CustomAlert';

import { useCollection } from "../hooks/useCollection";
  // import { Delete as DeleteIcon, InsertDriveFile as InsertDriveFileIcon } from '@mui/icons-material';
import { formatDistance } from 'date-fns';

import { useState, useRef, useEffect } from 'react';

import { useDocument } from '../hooks/useDocument';

import { collection, doc, deleteDoc, updateDoc } from 'firebase/firestore';

import { db } from "../firebase/config";

// import { useCollectionData } from 'react-firebase-hooks/firestore';

import {query, where, orderBy, limit, getDocs, startAfter } from 'firebase/firestore';
import { CustomButton2 } from './Button';
import ImageDialog from './UpdateDialog';

import EditIcon from '@mui/icons-material/Edit';

export default function UpdatesList({user}) {

  // ["isEditRequested", "==",  null]
    const queryC =  user.isAdmin ? null :  ["userId", "==",  user.uid];
    const orderByC =  ["date",  "desc"];
    const limitC = user.isAdmin ? 10 : 6;
    // let query2 = id ? ["isApproved", "==", true] : null;//todo uncomment and add to bellow line
    const { error: updateError, documents: updates, totalDocuments } = useCollection("updates", queryC, null, orderByC, limitC);

    const [loading, setLoading] = useState(false);

    const [newUpdates, setNewUpdates] = useState([]);

    const loadMoreUpdates = async () => {
      if (!loading) {
        const lastUpdate = newUpdates.length>0 ? newUpdates[newUpdates.length - 1] :  updates[updates.length - 1];
        if (lastUpdate) {
          setLoading(true);
          const updatesRef = collection(db, 'updates');
          const startAfterCondition = startAfter(lastUpdate.date);
          const queryCondition = queryC !=null ? where(...queryC): null;
          const orderByCondition = orderBy(...orderByC);
          const limitCondition = limit(limitC);
          const nextQuery = query(updatesRef, queryCondition, orderByCondition, startAfterCondition, limitCondition );
          const querySnapshot = await getDocs(nextQuery);
          setLoading(false);
          if (!querySnapshot.empty) {
            console.log(querySnapshot.size);
            const newDocuments = querySnapshot.docs.map((doc) => doc.data());
            // querySnapshot.size
            // Process the document data
            const prevUpdates = newUpdates;
            setNewUpdates([...prevUpdates, ...newDocuments]);
            console.log(newDocuments);
          } else {
            // Document not found
            console.log()
          } 
        }
      }
    };

    // const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertOpen, setAlertOpen] = useState(false);
    const handleAlertClose = () => {
      setAlertOpen(false);
    };

    const handleDeleteUpdateExternal = (updateId) => {
      console.log(updateId);
      if(newUpdates.length>0){
        const updatedUpdates = newUpdates.filter((update) => update.id !== updateId);
        setNewUpdates([...updatedUpdates]);
      }
    };
    const listRef = useRef(null);

    useEffect(() => {
        if(updates) { listRef.current.scrollTop = listRef.current.scrollHeight; }
      }, [updates]);

      
  
    return (
      <div>
      {loading && <CustomLinearProgress/>}

      {updateError && <Alert severity="error">{updateError}</Alert>}

      <CustomAlert alertOpen={alertOpen} alertSeverity={alertSeverity} handleAlertClose={handleAlertClose} alertMessage={alertMessage}/>
  
      {!user.isAdmin && updates && updates.length>=limitC && <CustomButton2 onClick={loadMoreUpdates}
      // marginBottom="100px"
      >
        {loading ? "Loading.." : "Load more"}
      </CustomButton2>}

      {updates && (
          <List ref={listRef}>
            {
           ( user.isAdmin ? [...updates, ...newUpdates]: 
            [...updates, ...newUpdates].reverse()).map((update) => {
              return (  
                <UpdateListItem
                key={update.id} update={update} currentUser={user}
                isAdmin={user.isAdmin}
                setAlertMessage={setAlertMessage}
                setAlertSeverity={setAlertSeverity}
                setAlertOpen={setAlertOpen}
                handleDeleteUpdateExternal={handleDeleteUpdateExternal}
                />
              );
            })}
          </List>
        )}

      {!user.isAdmin && <Box height = "50px"  pt={{ xs: 5, sm: 10  }}/>}

      {user.isAdmin && <CustomButton2 onClick={loadMoreUpdates}
      // marginBottom="100px"
      >
        {loading ? "Loading.." : "Load more"}
      </CustomButton2>}

      </div>
    );
  }
  


  function UpdateListItem({ update, currentUser, setAlertMessage, setAlertSeverity, setAlertOpen, handleDeleteUpdateExternal}) {

    const date = update.date.toDate(); // Convert Firestore Timestamp to JavaScript Date
    const timeOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      hourCycle: 'h12',
    }; // Set time options
  
    update.formatedDate = `${date.toLocaleDateString()} ${date.toLocaleTimeString([], timeOptions)}`;
  
    const now = new Date();
    const distance = formatDistance(update.date.toDate(), now);
    update.fromTime = `${distance} ago`;

    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedUpdate, setSelectedUpdate] = useState(null);
  
    const handleOpenImageDialog = (imageUrl) => {
      setSelectedUpdate(imageUrl);
      setDialogOpen(true);
    };
  
    const handleCloseImageDialog = () => {
      setSelectedUpdate(null);
      setDialogOpen(false);
    };

    
    const { document: updateUser, error } = useDocument("users", currentUser.isAdmin ? update.userId : update.isVerified ? update.ratedBy : "");

    const [rating, setRating] = useState(null);
    const [editRequest, setEditRequest] = useState(null);
  
    return (
      <>
      <ImageDialog
        currentUser={currentUser}
        updateUser= {updateUser}
        dialogOpen={dialogOpen}
        handleCloseImageDialog={handleCloseImageDialog}
        selectedUpdate={selectedUpdate}
        setAlertMessage={setAlertMessage}
        setAlertSeverity={setAlertSeverity}
        setAlertOpen={setAlertOpen}
        handleSetRating={setRating}
        handleEditRequest={setEditRequest}
        handleDeleteUpdateExternal={handleDeleteUpdateExternal}
      />
      <ListItem
        onClick={() => handleOpenImageDialog(update)}
        sx={{
          borderRadius: '10px',
          marginBottom: '10px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          '&:hover': { backgroundColor: 'grey.200', cursor: 'pointer' },
        }}
      >
      
        <ListItemText
          primary={
            
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {updateUser && <UserAvatar user={updateUser} />  }

          {(update.isEditRequested || editRequest) && <> <EditIcon fontSize='small' titleAccess='Edit requested'/> </>} {/* Show the EditIcon if isEditRequested is true */}

          {(update.rating ||  rating) && <Rating
            name={`rating-${update.id}`}
            value={rating ?? update.rating}
            size = 'small'
            disabled
            precision={1}
          />}

            {update.description}
            
            </Box>
          }
          primaryTypographyProps={{ variant: 'h6', color: 'textSecondary'}}
          secondary={
            <Typography variant="body1" color="textSecondary">
              {`Updated ${update.fromTime}`}
            </Typography>
          }
        />
      </ListItem>
      </>
    );
  }


  function UserAvatar({ user }) {
  
    return (
        <>
        {user && 
        <ListItemText>
        {/* <ListItemAvatar> */}
          <Avatar src={user.photoURL} alt={user.name} />
          <Typography variant="caption">{user.name}</Typography>
        {/* </ListItemAvatar> */}
        </ListItemText>
        }
      </>
    );
  }