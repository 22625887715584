import {
  Container,
  Box,
  Typography,
  Alert,
} from "@mui/material";
import { useState } from "react";
import { useSignup } from "../hooks/useSignup";
import { useNavigate } from "react-router-dom";

// components
import EnititySelector from "../components/EnititySelector";
import UserForm from "../components/UserForm";
import CustomLinearProgress from "../components/Loading";

export default function Register() {
  const [institution, setInstitution] = useState(null);
  const [internship, setInternship] = useState(null);
  const { signup, isPending, error } = useSignup();

  const selectInstitution = (value) => {
    setInstitution(value);
    console.log(value);
  };

  const selectInternship = (value) => {
    setInternship(value);
    console.log(value);
  };

  const submit = (value) => {
    signup({
      ...value,
      internship: internship, 
      institution: institution,
    });
  };

  const back = () => {
    if(internship && internship.isProfessional) 
      setInternship(null);
      setInstitution(null);
  };

  // console.log(internship.isProfessional);

  return (
    <Container
      maxWidth="lg"
      sx={{
        minHeight: { xs: "80vh", sm: "80vh", md: "81.2vh" },
        marginTop: "10px",
        // marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box>
        {!internship && <EnititySelector selection={selectInternship} collection={"internships"} title={"Education Program"} subTitle={"Select your Bonic Education program from below list."}/>}

        {internship && !internship.isProfessional && !institution  && <EnititySelector selection={selectInstitution} />}

        {(institution || (internship && internship.isProfessional)) && (
          <UserDetails back={back} onSubmit={submit} type={institution!=null ? institution.type :  "pro"} />
        )}

        {institution && error && (
          <Alert
            severity="error"
            sx={{
              marginBottom: "2rem",
            }}
          >
            {error}
          </Alert>
        )}

        {institution && isPending && <CustomLinearProgress/>}
      </Box>
    </Container>
  );
}

function UserDetails({ back, onSubmit, type }) {
  const navigate = useNavigate();
  return (
    <>
      <Typography
        color="text.primary"
        variant="h5"
        component="h1"
        textAlign={["center"]}
      >
        Personal details
      </Typography>
      {/* <Typography */}
        {/* color="text.primary" */}
        {/* variant="p" */}
        {/* component="p" */}
        {/* textAlign={["center"]} */}
        {/* sx={{ */}
          {/* maxWidth: "500px", */}
          {/* paddingBottom: "1rem", */}
        {/* }} */}
      {/* > */}
        {/* Payment page will be opened after registration. */}
      {/* </Typography> */}
      <Typography
        textAlign={["center"]}
        sx={{ mb: 1.5 }}
        color="text.secondary"
        variant="body2"
      >
        Agree to the{" "}
        <a
          href="/terms"
          onClick={(e) => {
            e.preventDefault();
            navigate("/terms");
          }}
        >
          terms and conditions.
        </a>
      </Typography>
      <UserForm
        back={back}
        onSubmit={onSubmit}
        buttonText="Register"
        type={type}
      />
    </>
  );
}
