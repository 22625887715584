import { Typography, Box, Container, Grid, Button } from "@mui/material";
import BackToTop from "../components/BackToTop";
import CustomButton from "../components/Button";
import { useRef, useState } from "react";
import PlanCard from "../components/PlanCard";
// import SocialMedia from "../components/SocialMedia";
import { useNavigate } from "react-router-dom";

export default function InternshipPage() {
  // const myRef = useRef(null);
  // const executeScroll = () => myRef.current.scrollIntoView();
  const navigate = useNavigate();

  return (
    <>
      <HeroSection
        title="Upskill yourself through Bonic Education!"
        onTap={() => navigate("/register")}
      />

      <InternshipSection
        title="What we provide?"
        subTitle="Transforming Your Ideas into Reality."
      />

      {/* <div ref={myRef}>
        <PlansSection
          title="Our plans"
          url="https://forms.gle/BeWsSRNY1YsKY7qH9"
        />
      </div> */}
      {/* <div ref={myRef}>
        <PortfolioSection title="Portfolio" />
      </div>

      <PartnersSection /> */}
      <BackToTop />
    </>
  );
}

function HeroSection({ title, onTap }) {
  return (
    <Box>
      <Container
        maxWidth="lg"
        sx={{
          paddingTop: { xs: "10px", sm: "10px", md: "70px" },
          paddingBottom: { xs: "20px", sm: "20px", md: "80px" },
        }}
      >
        <Grid container spacing={0}>
          <Grid item sm={12} md={7}>
            <Box
              component="img"
              sx={{
                // height: 233,
                // paddingRight: { md: "20px" },
                width: "100%",
                // maxHeight: { xs: 233, md: "80vh" },
                maxWidth: { xs: "100vw", sm: "100vw", md: "36vw" },
                // minHeight: "770px",
                objectFit: "cover",
                // paddingBottom: { xs: "50px", sm: "50px" },
              }}
              alt="Student learning graphics"
              src={require("../images/internship/internship.png")}
            />
          </Grid>

          <Grid
            item
            sm={12}
            md={5}
            sx={{
              paddingTop: { sx: "40px", sm: "40px", md: "60px" },
              paddingBottom: { sx: "20px", sm: "20px", md: "50px" },
            }}
          >
            <Typography
              variant="h3"
              component="h1"
              fontWeight="bold"
              sx={{
                marginTop: { xs: "30px", sm: "30px", md: "0px" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              {title}
            </Typography>
            <Box
              sx={{
                textAlign: { xs: "center", md: "left" },
              }}
            >
              <CustomButton onClick={onTap}>Join now</CustomButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

function ServiceCard({ title, description, image, altTag }) {
  return (
    <Box
      sx={{
        // marginLeft: { xs: "0px", sm: "0px", md: "20px" },
        padding: { xs: "20px", sm: "20px", md: "20px" },
        borderRadius: "10px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
        marginBottom: { xs: "20px", sm: "20px", md: "20px" },
      }}
    >
      <Box
        sx={{
          alignItems: "start",
          display: "flex",
          // alignContent: "center",
          // justifyContent: "space-between",
        }}
      >
        <Box
          component="img"
          sx={{
            marginRight: "20px",
          }}
          alt={altTag}
          src={image}
        />
        <Box>
          <Typography
            variant="h6"
            component="h2"
            fontWeight="bold"
            textAlign={["left"]}
          >
            {title}
          </Typography>
          <Typography variant="body2" component="p" textAlign={["left"]}>
            {description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

const services = [
  {
    title: "Internship",
    description:
      "One month/week internship at our office where you will learn and explore both hardware and software technologies.",
    image: require("../images/internship/service1.png"),
    altTag: "Web development",
  },
  {
    title: "Project guidance",
    description:
      "College project guidance, hardware/software development, documentation etc",
    image: require("../images/internship/service2.png"),
    altTag: "Mobile app development",
  },
  {
    title: "Training",
    description:
      "Training on Robotics, Python, Flutter, React, Embedded system development and more.",
    image: require("../images/internship/service3.png"),
    altTag: "Hardware development",
  },
];

function InternshipSection({ title, subTitle }) {
  return (
    <Box backgroundColor="#009DEB10">
      <Container
        maxWidth="lg"
        sx={{
          paddingTop: { xs: "60px", sm: "60px", md: "70px" },
          paddingBottom: { xs: "60px", sm: "60px", md: "50px" },
        }}
      >
        <Grid container spacing={0}>
          <Grid
            item
            sm={12}
            md={6}
            sx={{
              paddingTop: { sx: "40px", sm: "40px", md: "60px" },
              paddingBottom: { sx: "20px", sm: "20px", md: "50px" },
              paddingRight: { md: "20px" },
            }}
          >
            <Typography
              variant="h4"
              component="h2"
              fontWeight="bold"
              textAlign={["left"]}
              color="primary"
              sx={{
                paddingBottom: { xs: "20px", sm: "20px", md: "20px" },
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="h3"
              component="h3"
              fontWeight="bold"
              textAlign={["left"]}
              sx={{
                marginRight: { xs: "20px", sm: "20px", md: "100px" },
                marginBottom: { xs: "30px", sm: "30px", md: "0px" },
              }}
            >
              {subTitle}
            </Typography>
          </Grid>

          <Grid item sm={12} md={6}>
            {services.map((service, index) => (
              <ServiceCard key={index} {...service} />
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

const PlansWidget = (props) => {
  const { title, plans, url } = props;

  const handleButtonClick = () => {
    window.location.href = url;
  };

  return (
    <div>
      <Typography
        variant="h3"
        component="h3"
        fontWeight="bold"
        textAlign={["center"]}
        sx={{
          marginBottom: { xs: "40px", sm: "40px", md: "50px" },
        }}
      >
        {title}
      </Typography>
      <Grid container spacing={3}>
        {plans.map((plan) => (
          <Grid item xs={12} sm={4} md={4} key={plan.id}>
            <PlanCard {...plan} />
          </Grid>
        ))}
      </Grid>
      <Box
        textAlign="center"
        sx={{
          paddingTop: "30px",
        }}
      >
        <CustomButton onClick={handleButtonClick}>Register now</CustomButton>
      </Box>
    </div>
  );
};

const plans = [
  {
    id: 1,
    title: "Robotics Internship",
    description: "This is plan 1",
    price: "4850",
    image: require("../images/internship/plan1.png"),
    features: [
      "Hands-on training with both software and hardware tools.",
      "Makes a life-sized humanoid 3d printable robot.",
      "Internship and course completion certificates will be provided.",
      "1-month program",
      "40 activity points",
    ],
  },
  {
    id: 2,
    title: "App development Internship",
    description: "This is plan 2",
    price: "2800₹",
    image: require("../images/internship/plan2.png"),
    features: [
      "App development and deployment on android phones",
      "Course completion certificates will be provided.",
      "1-week program",
      "20 activity points",
    ],
  },
  {
    id: 3,
    title: "Project guidance + Internship",
    description: "This is plan 3",
    price: "4000₹",
    image: require("../images/internship/plan3.png"),
    features: [
      "Complete support on project design and development",
      "Course completion certificates will be provided.",
      "Training on any one of the selected topics",
      "5 days program",
    ],
  },
];

const PlansSection = (props) => {
  const { title, url } = props;

  return (
    <Box>
      <Container
        maxWidth="lg"
        sx={{
          paddingTop: { xs: "50px", sm: "50px", md: "70px" },
          paddingBottom: { xs: "50px", sm: "50px", md: "80px" },
        }}
      >
        <Grid container spacing={0}>
          <PlansWidget title={title} url={url} plans={plans} />
        </Grid>
      </Container>
    </Box>
  );
};
